// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: rgb(167, 204, 167) !important; /* color elements as green */
}
`, "",{"version":3,"sources":["webpack://./src/views/bpmnViewer/BpmnIo.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC,EAAE,4BAA4B;AACnE","sourcesContent":[".highlight:not(.djs-connection) .djs-visual > :nth-child(1) {\n  fill: rgb(167, 204, 167) !important; /* color elements as green */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
